import { render, staticRenderFns } from "./dashboard-no-wallet.vue?vue&type=template&id=be23b794&scoped=true&"
import script from "./dashboard-no-wallet.vue?vue&type=script&lang=ts&"
export * from "./dashboard-no-wallet.vue?vue&type=script&lang=ts&"
import style0 from "./dashboard-no-wallet.vue?vue&type=style&index=0&lang=scss&"
import style1 from "./dashboard-no-wallet.vue?vue&type=style&index=1&id=be23b794&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "be23b794",
  null
  
)

export default component.exports