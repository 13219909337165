





























































import { DashboardViewModel } from '../viewmodels/dashboard-viewmodel'
import { Observer } from 'mobx-vue'
import { Component, Vue, Inject } from 'vue-property-decorator'

import { walletStore } from '@/stores/wallet-store'
@Observer
@Component({
  components: {
    AutocompleteWallet: () => import('../components/AutocompleteWallet.vue'),
  },
})
export default class IdoPools extends Vue {
  @Inject() providers
  @Inject() vm!: DashboardViewModel
  // @Provide() providers = appProvider
  wallet = walletStore
}
